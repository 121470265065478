/* eslint-disable react/prop-types */
// @ts-check

import { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useMediaUser } from '../User';

/** @import { UserMediaStreamTrack, UserMediaStream } from '../User'; */

/**
 * @typedef {{
 * addCropVideoTrack: (track: UserMediaStreamTrack) => void,
 * cropOrUserVideoActiveTracks: UserMediaStreamTrack[],
 * cropOrUserActiveTracks: UserMediaStreamTrack[],
 * cropOrUserMediastreams: UserMediaStream[],
 * cropVideoActiveTracks: UserMediaStreamTrack[],
 * isCropEnabled: boolean,
 * removeCropVideoTrackByTrack: (track: UserMediaStreamTrack) => void,
 * personalCrop: any,
 * setLocalPersonalCrop: React.Dispatch<React.SetStateAction<any>>,
 * }} IMediaCropContext
 */

export const MediaCropContext = createContext(/** @type {IMediaCropContext}*/({}));
export const useMediaCrop = () => useContext(MediaCropContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaCropProviderProps
 */

/**
 * @typedef {{
 * 	factor: number,
 * 	x: number,
 * 	y: number,
 * 	width: number,
 * 	height: number,
 * 	orientation: boolean,
 * }} cropData
*/

/**
* @typedef {{
*   zoom?: { max: number }
* }} MediaTrackCapabilitiesZoom
*/

/**
* @typedef {{
*   zoom?: number
* }} MediaTrackConstraintSetZoom
*/

export const MediaCropProvider = (
	/** @type {MediaCropProviderProps} */
	{
		children,
	},
) => {
	const [personalCrop, setLocalPersonalCrop] = useState(
		/** @type {cropData | undefined} */(undefined),
	);
	const [cropVideoActiveTracks, setCropVideoActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const { userVideoActiveTracks, userMediastreams, userAudioActiveTracks } = useMediaUser();
	const [isMobilePortrait, setIsMobilePortrait] = useState(
		navigator.maxTouchPoints > 0
		&& window.matchMedia('(orientation: portrait)').matches,
	);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setIsMobilePortrait(navigator.maxTouchPoints > 0 && window.matchMedia('(orientation: portrait)').matches);
		});
		return () => {
			window.removeEventListener('resize', () => {
				setIsMobilePortrait(navigator.maxTouchPoints > 0 && window.matchMedia('(orientation: portrait)').matches);
			});
		};
	}, []);

	const addCropVideoTrack = useCallback((
		/** @type {UserMediaStreamTrack} */track,
	) => {
		setCropVideoActiveTracks((prev) => [...prev, track]);
	}, []);

	const removeCropVideoTrackByTrack = useCallback((
		/** @type {UserMediaStreamTrack} */track,
	) => {
		setCropVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
	}, []);

	const isCropEnabled = !!personalCrop;

	const cropOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isCropEnabled) {
			return [
				...cropVideoActiveTracks,
				...userVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return userVideoActiveTracks;
	}, [
		cropVideoActiveTracks,
		isCropEnabled,
		userVideoActiveTracks,
	]);

	const cropOrUserActiveTracks = useMemo(
		() => [
			...cropOrUserVideoActiveTracks,
			...userAudioActiveTracks,
		], [cropOrUserVideoActiveTracks, userAudioActiveTracks],
	);

	const cropOrUserMediastreams = useMemo(() => {
		if (cropVideoActiveTracks?.length > 0) {
			/** @type {UserMediaStream} */
			const cropMediaStream = new MediaStream();
			cropMediaStream.configId = 0;
			cropMediaStream.addTrack(cropVideoActiveTracks[0]);
			const userMediaStream = userMediastreams.find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) cropMediaStream.addTrack(track);
			}

			// Refresh cropMediaStream when tracks change to avoid player image stuck
			return [
				...userMediastreams.filter(({ configId }) => configId !== 0),
				cropMediaStream,
			];
		}
		return userMediastreams;
	}, [cropVideoActiveTracks, userMediastreams]);

	const calculateZoomCoordinates = useCallback((zoomFactor) => {
		const canvasSize = isMobilePortrait ? { width: 720, height: 1280 }
			: { width: 1280, height: 720 };

		const canvasCenter = {
			x: canvasSize.width / 2,
			y: canvasSize.height / 2,
		};

		const zoomedWidth = canvasSize.width / zoomFactor;
		const zoomedHeight = canvasSize.height / zoomFactor;

		const offsetX = (canvasSize.width - zoomedWidth) * (canvasCenter.x / canvasSize.width);
		const offsetY = (canvasSize.height - zoomedHeight) * (canvasCenter.y / canvasSize.height);

		const topLeft = {
			x: offsetX,
			y: offsetY,
		};

		const topRight = {
			x: offsetX + zoomedWidth,
			y: offsetY,
		};

		const bottomLeft = {
			x: offsetX,
			y: offsetY + zoomedHeight,
		};

		return {
			x: topLeft.x,
			y: topLeft.y,
			width: topRight.x - topLeft.x,
			height: bottomLeft.y - topLeft.y,
		};
	}, [isMobilePortrait]);

	const getCapabilities = useCallback(() => {
		if (userVideoActiveTracks?.length <= 0) return undefined;
		/** @type {MediaTrackCapabilities & MediaTrackCapabilitiesZoom} */
		const capabilities = userVideoActiveTracks[0].getCapabilities();
		return capabilities;
	}, [userVideoActiveTracks]);

	const applyZoomConstraints = useCallback((videoTrack, constraints) => {
		videoTrack.applyConstraints({
			advanced: [
				constraints,
			],
		});
	}, []);

	/**
	 * @param {cropData} cropData
	 */
	const setPersonalCrop = useCallback((cropData) => {
		if (!cropData) {
			setLocalPersonalCrop(undefined);
			return;
		}
		const capabilities = getCapabilities();
		if (capabilities?.zoom) {
			setLocalPersonalCrop(undefined);
			const parsedFactor = Number.parseFloat(cropData.factor);
			if (capabilities.zoom.max >= parsedFactor) {
				applyZoomConstraints(userVideoActiveTracks[0], { zoom: cropData.factor });
			} else {
				applyZoomConstraints(userVideoActiveTracks[0], { zoom: capabilities.zoom.max });
				const difference = 1 + (parsedFactor - capabilities.zoom.max);
				const { x, y, width, height } = calculateZoomCoordinates(difference);
				setLocalPersonalCrop({
					factor: difference,
					x,
					y,
					width,
					height,
					orientation: isMobilePortrait,
				});
			}
		} else {
			setLocalPersonalCrop(cropData);
		}
	}, [
		userVideoActiveTracks,
		calculateZoomCoordinates,
		isMobilePortrait,
		applyZoomConstraints,
		getCapabilities,
	]);

	const contextValue = useMemo(() => ({
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		getCapabilities,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setLocalPersonalCrop,
		setPersonalCrop,
	}), [
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		getCapabilities,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setLocalPersonalCrop,
		setPersonalCrop,
	]);

	return (
		<MediaCropContext.Provider value={contextValue}>
			{children}
		</MediaCropContext.Provider>
	);
};
