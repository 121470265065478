// @ts-check

import { batch } from 'react-redux';

import {
	startRecording,
	stopRecording,
} from '../../api/ws/mixing';
import {
	soupSession,
	publishTrack,
	unpublishTrack,
	updateTrack,
} from '../../api/soup';
import Track from '../../lib/store/track';
import {
	addTrackAction,
	removeTrackAction,
} from './tracks';
import {
	addStreamTrackAction,
	removeStreamTrackAction,
	removeChannelStreamsAction,
	updateStreamAction,
} from './channelStreams';
import {
	addStreamPublicationAction,
	clearStreamPublicationAction,
	removeStreamPublicationAction,
	unsubscribePublicationsAction,
} from './publications';
import { selectChannelOtherStreams, selectIsPublicationChannelStreamStored } from '../selectors/channelStreams';
import { selectIsPublicationStored } from '../selectors/publications';

/**
 * @import { SubscribedPublication, Publication } from '../../lib/store/publication';
 * @import { RootState, AppAction } from '..':
 * @import { ThunkAction } from 'redux-thunk';
 * @import { Action } from 'redux';
 * @import { AuthenticatedUser } from '../../components/ReactVideo/ReactVideo';
 */

const ACTION_KEY = 'channelMixing';

/**
 * actions
 */
const START_RECORDING = `${ACTION_KEY}/startRecording`;
const STOP_RECORDING = `${ACTION_KEY}/stopRecording`;
const PUBLISH_TRACK = `${ACTION_KEY}/publishTrack`;
const UNPUBLISH_TRACK = `${ACTION_KEY}/unpublishTrack`;
const UPDATE_TRACK = `${ACTION_KEY}/updateTrack`;

/**
 * @param {Publication} publication
 * @param {string} peerId
 * @param {string} userId
 * @returns {boolean}
 */
const shouldSkipPublication = (publication, peerId, userId) => {
	const { appData = /** @type {Publication['appData']} */({}) } = publication;
	const { peerExceptions, peerReceiver, talkback } = appData;

	// The publication is dedicated to a single different peer
	if (peerReceiver && peerReceiver !== peerId) return true;

	// The publication should not be subscribed by this peer
	if (peerExceptions && peerExceptions.includes(peerId)) return true;

	// The publication is a talkback not concerned by this user
	if (
		talkback && talkback.senderUserId !== userId && talkback.receiverUserId !== userId
	) return true;

	return false;
};

/**
 * @param {AuthenticatedUser} user
 * @returns {ThunkAction<() => void, RootState, unknown, AppAction>}
 */
export const addSoupListenersAction = (user) => (dispatch, getState) => {
	const soup = soupSession();
	if (!soup) throw new Error('No soup session');

	/**
	 * @param {Publication} publication
	 */
	const onTrackPublished = (publication) => {
		const { peerId } = publication;

		if (shouldSkipPublication(publication, soup.peerId, user.sub)) return;

		if (peerId !== soup.peerId) {
			dispatch(addStreamPublicationAction(publication));
		} else {
			// For own publication, we don't add the publication
			// but we still add a "fake" streamTrack to the store
			// with producer ids instead of track ids.
			// It allows to add the stream in guests sources
			dispatch(addStreamTrackAction({
				...publication,
				own: true,
				mediaStreamTrack: { id: publication.producerId },
			}));
		}
	};

	/**
	 * @param {Publication} publication
	 */
	const onTrackUnpublished = (publication) => {
		const { peerId } = publication;

		if (peerId === soup.peerId) {
			// For own publication, we just remove the "fake" streamTrack
			// with producer ids instead of track ids.
			// It allows to remove the stream from guests sources
			dispatch(removeStreamTrackAction({
				...publication,
				mediaStreamTrack: { id: publication.producerId },
			}));
		} else {
			dispatch(removeStreamPublicationAction(publication));
		}
	};

	/**
	 * @param {SubscribedPublication} subscription
	 */
	const onTrackSubscribed = (subscription) => {
		const { mediaStreamTrack } = subscription;
		const track = new Track(mediaStreamTrack);
		dispatch(addTrackAction(track));
		dispatch(addStreamTrackAction(subscription));
	};

	/**
	 * @param {SubscribedPublication} subscription
	 */
	const onTrackUnsubscribed = (subscription) => {
		const { mediaStreamTrack } = subscription;
		const track = new Track(mediaStreamTrack);
		dispatch(removeTrackAction(track));
		dispatch(removeStreamTrackAction(subscription));
	};

	/**
	 * @param {Publication} publication
	 */
	const onTrackUpdated = (publication) => {
		if (shouldSkipPublication(publication, soup.peerId, user.sub)) {
			/* If the appData changed, maybe it's time to unsubscribe a previously
			subscribed publication */
			const isSubscribed = !!soup.consumptions.get(publication.producerId);
			if (isSubscribed) {
				dispatch(unsubscribePublicationsAction([publication]));
			}

			const isPublicationStored = selectIsPublicationStored(getState(), publication);
			if (isPublicationStored) {
				onTrackUnpublished(publication);
			}
		} else {
			onTrackPublished(publication);
		}

		const isPublicationChannelStreamStored = selectIsPublicationChannelStreamStored(
			getState(),
			publication,
		);
		if (isPublicationChannelStreamStored) {
			dispatch(updateStreamAction(publication));
		}
	};

	const onConnected = async () => {
		dispatch(clearStreamPublicationAction());

		batch(() => {
			soup.publications.forEach((
				/** @type {Publication} */publication,
			) => {
				onTrackPublished(publication);
			});
		});
	};

	const onDisconnected = async () => {
		const channelStreams = selectChannelOtherStreams(getState(), { hashtag: soup.hashtag });
		channelStreams.forEach((stream) => {
			stream.tracks.forEach((trackId) => {
				const track = new Track({ id: trackId });
				dispatch(removeTrackAction(track));
			});
		});
		dispatch(clearStreamPublicationAction());
		dispatch(removeChannelStreamsAction({ hashtag: soup.hashtag }));
	};

	soup.on('trackPublished', onTrackPublished);
	soup.on('trackUnpublished', onTrackUnpublished);
	soup.on('trackSubscribed', onTrackSubscribed);
	soup.on('trackUnsubscribed', onTrackUnsubscribed);
	soup.on('trackUpdated', onTrackUpdated);
	soup.on('connected', onConnected);
	soup.on('disconnected', onDisconnected);

	if (soup.connected) onConnected();

	return function removeSoupListeners() {
		onDisconnected();
		soup.off('trackPublished', onTrackPublished);
		soup.off('trackUnpublished', onTrackUnpublished);
		soup.off('trackSubscribed', onTrackSubscribed);
		soup.off('trackUnsubscribed', onTrackUnsubscribed);
		soup.off('trackUpdated', onTrackUpdated);
		soup.off('connected', onConnected);
		soup.off('disconnected', onDisconnected);
	};
};

/**
 * @typedef {Action<typeof PUBLISH_TRACK> & { payload: () => ReturnType<typeof publishTrack> } |
* 		Action<typeof UNPUBLISH_TRACK> & { payload: ReturnType<typeof unpublishTrack> } |
* 		Action<typeof START_RECORDING> & { payload: ReturnType<typeof startRecording> } |
* 		Action<typeof STOP_RECORDING> & { payload: ReturnType<typeof stopRecording> } |
* 		Action<typeof UPDATE_TRACK> & { payload: ReturnType<typeof updateTrack> }
* } ChannelMixingAction
* */

/**
 * @param {MediaStreamTrack} track
 * @param {string} streamId
 * @param {AuthenticatedUser} user
 * @param {boolean} preventLarsens
 * @param {{
 * 	color: {
* 		r: number,
* 		g: number,
* 		b: number,
* 	},
* 	sensitivity: number,
 * }?} alphaColor
 * @param {*} trackEncodings // TODO : type trackEncodings
 * @param {{
 * 	senderUserId: string,
 *  receiverUserId: string,
 * }} talkback
 * @returns {ChannelMixingAction}
 */
export const publishTrackAction = (
	track,
	streamId,
	user,
	preventLarsens = false,
	alphaColor,
	trackEncodings,
	talkback,
) => ({
	type: PUBLISH_TRACK,
	payload: async () => {
		const { sub: userId, picture: avatar, nickname } = user;
		return publishTrack(
			track,
			{ preventLarsens, streamId, user: { avatar, nickname, userId }, alphaColor, talkback },
			trackEncodings,
		);
	},
});

/**
 * @param {{ id: string }} track
 * @returns {ChannelMixingAction}
 */
export const unpublishTrackAction = (track) => ({
	type: UNPUBLISH_TRACK,
	payload: unpublishTrack(track),
});

/**
 * @param {string} hashtag
 * @param {number} durationMinutes
 * @returns {ChannelMixingAction}
 */
export const startRecordingAction = (hashtag, durationMinutes) => ({
	type: START_RECORDING,
	payload: startRecording(hashtag, durationMinutes),
});

/**
 * @param {string} hashtag
 * @returns {ChannelMixingAction}
 */
export const stopRecordingAction = (hashtag) => ({
	type: STOP_RECORDING,
	payload: stopRecording(hashtag),
});

/**
 * @param {{ id: string }} track
 * @param {Partial<Publication['appData']>} appdata
 * @param {AuthenticatedUser} user
 * @returns {ChannelMixingAction}
 */
export const updateTrackAction = (track, appdata, user) => ({
	type: UPDATE_TRACK,
	payload: updateTrack(
		track,
		{
			...appdata,
			user: {
				userId: user.sub,
				avatar: user.picture,
				nickname: user.nickname,
			},
		},
	),
});
