// @ts-check

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useConst } from '../../../lib/hooks';

import { createCanvasAndContext } from '../../../lib/canvas';
import { useMediaCrop } from './Provider';

/**
 * @typedef {ReturnType<typeof createCanvasAndContext>} CanvasAndContext
 * @typedef {{
 *   size: { width: number, height: number }
 * }} Size
 * @typedef {CanvasAndContext & Size} CanvasAndContextSize
 */

/**
 * @typedef {{
 *   isCrop: boolean
 * }} CropTrack
 * @typedef {MediaStreamTrack & CropTrack} MediaStreamCropTrack
 */

let canvasAndContext;

export const useMediaCropPerformer = (userVideoTrack, personalCrop) => {
	const source = useConst(() => document.createElement('video'));
	const id = useRef(/** @type {number|undefined} */(undefined));
	const [cropVideoTrack, setCropVideoTrack] = useState(
		/** @type {MediaStreamCropTrack|undefined} */(undefined),
	);
	const { setPersonalCrop } = useMediaCrop();

	useEffect(() => {
		const handleOrientationChange = () => {
			const orientation = !window.matchMedia('(orientation: portrait)').matches;
			if (personalCrop?.orientation !== orientation) {
				setPersonalCrop(undefined);
				if (id.current) cancelAnimationFrame(id.current);
				setCropVideoTrack(undefined);//utile ?
				canvasAndContext = undefined;
			}
		};
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, [personalCrop, setPersonalCrop]);

	const perform = useCallback(() => {
		if (!canvasAndContext) {
			const { width, height } = userVideoTrack.getSettings();
			canvasAndContext = /** @type {CanvasAndContextSize} */(createCanvasAndContext({
				offscreen: false,
				width,
				height,
			}));
			canvasAndContext.size = { width, height };

			const tracks = /** @type {[MediaStreamCropTrack]}*/(
				/** @type {HTMLCanvasElement} */(canvasAndContext.canvas).captureStream(30).getVideoTracks()
			);
			const cropTrack = tracks[0];
			cropTrack.isCrop = true;
			setCropVideoTrack(cropTrack);
		}
		const ctx = canvasAndContext.context;

		ctx.clearRect(
			0,
			0,
			canvasAndContext.size.width,
			canvasAndContext.size.height,
		);
		ctx.drawImage(
			source,
			personalCrop.x,
			personalCrop.y,
			personalCrop.width,
			personalCrop.height,
			0,
			0,
			canvasAndContext.size.width,
			canvasAndContext.size.height,
		);

		if (personalCrop && userVideoTrack) {
			id.current = window.requestAnimationFrame(perform);
		}
	}, [
		source,
		userVideoTrack,
		personalCrop,
	]);

	useEffect(() => {
		if (personalCrop && userVideoTrack) {
			const videotrack = new MediaStream([userVideoTrack]);
			source.setAttribute('playsinline', '');
			source.autoplay = true;
			source.muted = true;
			source.srcObject = videotrack;
			source.oncanplay = () => {
				source.play();
				perform();
			};
		} else if (!personalCrop) {
			if (id.current) cancelAnimationFrame(id.current);
			setCropVideoTrack(undefined);
			canvasAndContext = undefined;
		}
		return () => {
			if (id.current) cancelAnimationFrame(id.current);
		};
	}, [perform, source, userVideoTrack, personalCrop]);

	return useMemo(
		() => ({
			cropVideoTrack,
		}),
		[
			cropVideoTrack,
		],
	);
};
