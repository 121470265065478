import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash.throttle';

import RecordingState from '../RecordingState/RecordingState';
import { ChannelLoading } from '../ChannelLoading/Loading';
import { PlayerLive } from '../PlayerLive/PlayerLive';
import { isCamStreamId } from '../../store/lib/helpers';
import { selectLiveMediaStream } from '../../store/selectors/mediaStreams';
import { selectLiveStreamPublication } from '../../store/selectors/publications';
import { subscribePublicationsAction } from '../../store/actions/publications';
import { useDispatch, useSelector } from '../ReactVideo/Provider';
import { useSoup } from '../ReactVideo/Soup';
import { PlayerLiveVuMeter } from '../PlayerLive/PlayerLiveVuMeter';
import { usePlayerLiveVuMeter } from '../PlayerLive/PlayerLiveVuMeterProvider';

const SUBSCRIBE_THROTTLE_DELAY = 250;

export const ChannelPlayer = ({
	children,
	className,
	handleClickFullScreen,
	hashtag,
	isFullscreen,
	safeZoneComponent,
	isHost,
}) => {
	const dispatch = useDispatch();
	const { getIsConnected } = useSoup();
	const { isEnabled } = usePlayerLiveVuMeter();

	const liveStreamPublication = useSelector(selectLiveStreamPublication);
	const liveMediaStream = useSelector((state) => selectLiveMediaStream(state, { hashtag }));
	const publications = liveStreamPublication?.publications;

	const throttleSubscribe = useMemo(() => throttle((pubs) => {
		if (pubs && getIsConnected()) {
			dispatch(subscribePublicationsAction(pubs));
		}
	}, SUBSCRIBE_THROTTLE_DELAY), [dispatch, getIsConnected]);

	useEffect(() => () => { throttleSubscribe.cancel(); }, [throttleSubscribe]);

	useEffect(() => {
		throttleSubscribe(publications);
	}, [getIsConnected, publications, throttleSubscribe]);

	return (
		<ChannelLoading loading={!getIsConnected()}>
			<div className={clsx(className, 'position-relative d-flex flex-column justify-content-center overflow-hidden align-items-center h-100 w-100')}>
				<RecordingState />
				<PlayerLive
					handleClickFullScreen={handleClickFullScreen}
					isCameraOnMainLayer={!!liveMediaStream?.stream?.sources?.some((source) => isCamStreamId(source.streamId) && source.layer === 'main')}
					isFullscreen={isFullscreen}
					safeZoneComponent={safeZoneComponent}
					src={liveMediaStream}
				>
					{children}
				</PlayerLive>
				{isEnabled && isHost && (<PlayerLiveVuMeter className="position-absolute bottom-0 right-0 h-50 me-1" mediastream={liveMediaStream} />)}
			</div>
		</ChannelLoading>
	);
};

ChannelPlayer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	handleClickFullScreen: PropTypes.func.isRequired,
	hashtag: PropTypes.string.isRequired,
	isFullscreen: PropTypes.bool,
	safeZoneComponent: PropTypes.elementType,
	isHost: PropTypes.bool,
};

ChannelPlayer.defaultProps = {
	children: null,
	className: '',
	isFullscreen: false,
	safeZoneComponent: null,
	isHost: false,
};
