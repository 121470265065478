// @ts-check

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as mixing from '../../api/ws/mixing';
import { useReactVideo } from './Provider';
import { useSoup } from './Soup';
import { useDrawer } from '../PlayerLive/PlayerCanvasDrawerProvider';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { useNickname } from '../Nickname/NicknameProvider';
import { usePipLayout } from '../ChannelButtonsPanel/PipLayoutProvider';

/**
 * @typedef {import('../../../../../webrtc-router/src/index')
 * 	.ChannelMixerStatus} ChannelMixerStatus
 * @typedef {import('../../../../../webrtc-router/src/lib/mixer/Channel')
 * 	.SourceToCreate} SourceToCreate
 * */

// TODO : finish typing
/**
 * @typedef {{
 * channelMixerStatus: ChannelMixerStatus,
 * setSource: (source: SourceToCreate, priorityLayer?: string | null) => Promise<void>,
 * clearLayer: (priorityLayer?: string | null) => Promise<void>,
* }} IMixingContext
*/

export const MixingContext = createContext(
	/** @type {IMixingContext | undefined} */(undefined),
);

export const useMixing = () => {
	const mixingContext = useContext(MixingContext);
	// type guard (removes undefined type)
	if (!mixingContext) {
		throw new Error('useMixing must be used within a MixingProvider');
	}
	return mixingContext;
};

export const MIXING_CONNECTING_STATE_CONNECTED = 'MIXING_CONNECTING_STATE_CONNECTED';
export const MIXING_CONNECTING_STATE_DISCONNECTED = 'MIXING_CONNECTING_STATE_DISCONNECTED';
export const MIXING_CONNECTING_STATE_PENDING = 'MIXING_CONNECTING_STATE_PENDING';

/**
 * @typedef {{
* 	children: React.ReactNode,
* }} MixingProviderProps
 */

export const MixingProvider = (
	/** @type {MixingProviderProps} */
	{ children },
) => {
	const { isController, getConnectionConfig, user } = useReactVideo();
	const { hashtag, getIsConnected } = useSoup();
	const { setIsDrawEnabled } = useDrawer();
	const [channelMixerStatus, setChannelMixerStatus] = useState();
	const [connectingState, setConnectingState] = useState(MIXING_CONNECTING_STATE_DISCONNECTED);
	const [layer, setLayer] = useState('main');
	const isConnected = connectingState === MIXING_CONNECTING_STATE_CONNECTED;
	const { handleCMSEvents } = useMediaKey();
	const { setIsDrawNicknameEnabled } = useNickname();
	const { setDisplayMode } = usePipLayout();

	const join = useCallback(async (isAborted) => {
		try {
			console.log('[joinMixing]');
			setConnectingState(MIXING_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (isAborted()) return;
			await mixing.connect(connectionConfig);
			if (isAborted()) return;
			await mixing.join(hashtag);
			if (isAborted()) return;
			setConnectingState(MIXING_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(MIXING_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig, hashtag]);

	const leave = useCallback(async () => {
		setConnectingState(MIXING_CONNECTING_STATE_DISCONNECTED);
		console.log('[leaveMixing]');
		await mixing.disconnect();
	}, []);

	useEffect(() => {
		if (getIsConnected()) {
			let aborted = false;
			const isAborted = () => aborted;
			join(isAborted);
			return () => {
				aborted = true;
				leave();
			};
		}
		return undefined;
	}, [getIsConnected, join, leave]);

	const clearLayer = useCallback(async (priorityLayer = null) => {
		console.log('clearLayer', hashtag, (priorityLayer ?? layer));
		return mixing.clearLayer(hashtag, (priorityLayer ?? layer));
	}, [hashtag, layer]);

	const setSource = useCallback(async (
		/** @type {SourceToCreate} */source,
		/** @type {string?} */priorityLayer = null,
	) => {
		console.log('setSource', hashtag, source, (priorityLayer ?? layer));
		return mixing.setSource(hashtag, source, (priorityLayer ?? layer));
	}, [hashtag, layer]);

	const notifyNewDrawing = useCallback(async (drawing) => {
		console.log('setDrawing', hashtag, { drawing });
		return mixing.notifyNewDrawing(hashtag, drawing);
	}, [hashtag]);

	const notifyClearDrawing = useCallback(async () => {
		console.log('clearDrawing', hashtag);
		return mixing.notifyClearDrawing(hashtag);
	}, [hashtag]);

	const notifyClearAllDrawings = useCallback(async () => {
		console.log('clearAllDrawings', hashtag);
		return mixing.notifyClearAllDrawings(hashtag);
	}, [hashtag]);

	const notifyToggleDrawings = useCallback(async (allowedUser) => {
		console.log('toggleDrawings', hashtag, allowedUser);
		return mixing.notifyToggleDrawings(hashtag, allowedUser);
	}, [hashtag]);

	const notifyToggleDrawingsBackground = useCallback(async (data) => {
		console.log('toggleDrawingsBackground', hashtag, data);
		return mixing.notifyToggleDrawingsBackground(hashtag, data);
	}, [hashtag]);

	const notifyKeyConfig = useCallback(async (config) => {
		console.log('keyConfig', { hashtag, config });
		return mixing.notifyKeyConfig(hashtag, config);
	}, [hashtag]);

	const setSourceVolume = useCallback(async (source, volume) => {
		console.log('setSourceVolume', hashtag, source, volume);
		return mixing.setSourceVolume(hashtag, source, volume);
	}, [hashtag]);

	const setSourceTimecodes = useCallback(async (source, timecodes) => {
		console.log('setSourceTimecodes', hashtag, source, timecodes);
		return mixing.setSourceTimecodes(hashtag, source, timecodes);
	}, [hashtag]);

	const setSourceProgresstime = useCallback(async (source, progresstime) => {
		console.log('setSourceProgresstime', hashtag, source, progresstime);
		return mixing.setSourceProgresstime(hashtag, source, progresstime);
	}, [hashtag]);

	const setSourceSpeed = useCallback(async (source, speed) => {
		console.log('setSourceSpeed', hashtag, source, speed);
		return mixing.setSourceSpeed(hashtag, source, speed);
	}, [hashtag]);

	const notifyTogglePlayPauseVideoSource = useCallback(async (source) => {
		console.log('notifyTogglePlayPauseVideoSource', { hashtag, source });
		return mixing.togglePlayPauseVideoSource(hashtag, source);
	}, [hashtag]);

	const notifyToggleLoopVideoSource = useCallback(async (source) => {
		console.log('notifyToggleLoopVideoSource', { hashtag, source });
		return mixing.toggleLoopVideoSource(hashtag, source);
	}, [hashtag]);

	const changePiPPosition = useCallback(async ({ x, y, pipLayer, width, height }) => {
		console.log('setPiPPosition', { hashtag, x, y, pipLayer, width, height });
		return mixing.changePiPPosition(hashtag, x, y, pipLayer, width, height);
	}, [hashtag]);

	const notifyModeChange = useCallback(async (mode) => {
		console.log('notifyModeChange', { hashtag, mode });
		return mixing.publishModeChange(hashtag, mode);
	}, [hashtag]);

	const notifyAddPersistentAudioSource = useCallback(async (sourceId) => {
		console.log('notifyAddPersistentAudioSource', { hashtag, sourceId });
		return mixing.publishPersistentAdd(hashtag, sourceId);
	}, [hashtag]);

	const notifyRemovePersistentAudioSource = useCallback(async (sourceId) => {
		console.log('notifyRemovePersistentAudioSource', { hashtag, sourceId });
		return mixing.publishPersistentRemove(hashtag, sourceId);
	}, [hashtag]);

	const notifyAutomaticSwitchChange = useCallback(async (isEnabled) => {
		console.log('notifyAutomaticSwitchChange', { hashtag, isEnabled });
		return mixing.publishAutomaticSwitchChange(hashtag, isEnabled);
	}, [hashtag]);

	const notifyCrop = useCallback(async (rect) => {
		console.log('notifyCrop', { hashtag, rect });
		return mixing.notifyCrop(hashtag, rect);
	}, [hashtag]);

	const notifyDrawNickname = useCallback(async (isEnabled) => {
		console.log('notifyDrawNickname', { hashtag, isEnabled });
		return mixing.notifyDrawNickname(hashtag, isEnabled);
	}, [hashtag]);

	const notifyPipMode = useCallback(async (mode) => {
		console.log('notifyPipMode', { hashtag, mode });
		return mixing.notifyPipMode(hashtag, mode);
	}, [hashtag]);

	const updateText = useCallback(async (text) => {
		console.log('updateText', { hashtag, text });
		return mixing.publishTextUpdate(hashtag, text);
	}, [hashtag]);

	const notifySceneApply = useCallback(async (scene) => {
		console.log('notifySceneApply', { hashtag, scene });
		return mixing.publishSceneApply(hashtag, scene);
	}, [hashtag]);

	const notifyAutomaticFillChange = useCallback(async (isEnabled) => {
		console.log('notifyAutomaticFillChange', { hashtag, isEnabled });
		return mixing.publishAutomaticFillChange(hashtag, isEnabled);
	}, [hashtag]);

	const notifyCanvasLogoChange = useCallback(async (showDefaultLogo, logoUrl) => {
		console.log('notifyCanvasLogoChange', { hashtag, showDefaultLogo, logoUrl });
		return mixing.notifyCanvasLogoChange(hashtag, showDefaultLogo, logoUrl);
	}, [hashtag]);

	useEffect(() => {
		if (isConnected) {
			const offEventStatus = mixing.onEventStatus((cms) => {
				console.log('onEventStatus', cms);
				setChannelMixerStatus(cms);
				const allowDraw = cms.allowedUserToDraw === user.sub || cms.allowedUserToDraw === 'all';
				setIsDrawEnabled(isController || allowDraw);
				handleCMSEvents(cms, user.sub, cms.hashtag === user.preferred_username);
				setIsDrawNicknameEnabled(!!cms.isDrawNicknameEnabled);
				if (cms.displayMode) {
					setDisplayMode(cms.displayMode);
				}
			});

			mixing.status(hashtag); // Ask for initial mixer status

			return () => {
				setChannelMixerStatus(undefined);
				offEventStatus();
			};
		}
		return undefined;
	}, [
		isController,
		handleCMSEvents,
		hashtag,
		isConnected,
		setIsDrawEnabled,
		setIsDrawNicknameEnabled,
		user,
		setDisplayMode,
	]);

	const contextValue = useMemo(() => ({
		channelMixerStatus,
		clearLayer,
		connectingState,
		isConnected,
		layer,
		setLayer,
		setSource,
		setSourceVolume,
		setSourceTimecodes,
		setSourceSpeed,
		setSourceProgresstime,
		notifyNewDrawing,
		notifyClearDrawing,
		notifyClearAllDrawings,
		notifyDrawNickname,
		notifyKeyConfig,
		notifyToggleDrawings,
		notifyToggleDrawingsBackground,
		changePiPPosition,
		notifyModeChange,
		notifyAddPersistentAudioSource,
		notifyRemovePersistentAudioSource,
		notifyAutomaticSwitchChange,
		notifyCrop,
		notifyPipMode,
		notifyTogglePlayPauseVideoSource,
		notifyToggleLoopVideoSource,
		updateText,
		notifySceneApply,
		notifyAutomaticFillChange,
		notifyCanvasLogoChange,
	}), [
		channelMixerStatus,
		clearLayer,
		connectingState,
		isConnected,
		layer,
		setLayer,
		setSource,
		setSourceVolume,
		setSourceTimecodes,
		setSourceSpeed,
		setSourceProgresstime,
		notifyNewDrawing,
		notifyClearDrawing,
		notifyClearAllDrawings,
		notifyDrawNickname,
		notifyKeyConfig,
		notifyToggleDrawings,
		notifyToggleDrawingsBackground,
		changePiPPosition,
		notifyModeChange,
		notifyAddPersistentAudioSource,
		notifyRemovePersistentAudioSource,
		notifyAutomaticSwitchChange,
		notifyCrop,
		notifyPipMode,
		notifyTogglePlayPauseVideoSource,
		notifyToggleLoopVideoSource,
		updateText,
		notifySceneApply,
		notifyAutomaticFillChange,
		notifyCanvasLogoChange,
	]);

	return (
		<MixingContext.Provider value={contextValue}>
			{children}
		</MixingContext.Provider>
	);
};

MixingProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
