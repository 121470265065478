/* eslint-disable react/prop-types */
// @ts-check

import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMediaUser } from '../User';

/** @import { UserMediaStreamTrack } from '../User'; */

/**
 * @typedef {UserMediaStreamTrack & {
 * 	originalTrackId?: string,
 * }} TalkbackMediaStreamTrack
 */

/**
 * @typedef {{
 * 	_id: string,
 * 	nickname: string,
 * }} TalkbackRecipientUser
 */

/**
 * @typedef {{
 *	isDisconnecting: boolean,
 *	recipientUser: TalkbackRecipientUser?,
 * 	startTalkback: (user: TalkbackRecipientUser) => void,
 * 	stopTalkback: () => void,
 *	talkbackActiveTrack: TalkbackMediaStreamTrack?,
 *	userAudioRequestError?: import('../User').MediaRequestError,
 * }} IMediaTalkbackContext
 */

const MediaTalkbackContext = createContext(/** @type {IMediaTalkbackContext} */({}));

export const useMediaTalkback = () => useContext(MediaTalkbackContext);

const CONFIG_ID = 0;
const STOP_TALKBACK_DEBOUNCE_TIME = 750;

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaTalkbackProps
 */

export const MediaTalkback = (
	/** @type {MediaTalkbackProps} */
	{
		children,
	},
) => {
	const {
		toggleAudio,
		getIsUserAudioActive,
		userAudioActiveTracks,
		userAudioRequestErrors,
	} = useMediaUser();

	const audioWasOffBeforeTalkback = useRef(false);
	const [stopTalkbackWhenAudioOff, setStopTalkbackWhenAudioOff] = useState(false);
	const stopTalkbackTimer = useRef(/** @type {NodeJS.Timeout?} */(null));

	const [recipientUser, setRecipientUser] = useState(
		/** @type {TalkbackRecipientUser?} */(null),
	);

	const talkbackTrackRef = useRef(/** @type {TalkbackMediaStreamTrack?}*/(null));
	const talkbackActiveTrack = useMemo(() => {
		// Stop existing talkback track on dependency change
		talkbackTrackRef.current?.stop();
		talkbackTrackRef.current = null;

		if (!recipientUser) return null;

		const defaultMicrophone = userAudioActiveTracks.find((track) => track.configId === CONFIG_ID);
		if (!defaultMicrophone) return null;

		/** @type {TalkbackMediaStreamTrack} */
		const talkbackTrack = defaultMicrophone.clone();
		talkbackTrack.originalTrackId = defaultMicrophone.id;
		talkbackTrackRef.current = talkbackTrack;
		return talkbackTrack;
	}, [recipientUser, userAudioActiveTracks]);

	const startTalkback = useCallback((
		/** @type {TalkbackRecipientUser} */user,
	) => {
		if (stopTalkbackTimer.current) {
			clearTimeout(stopTalkbackTimer.current);
		}
		if (recipientUser === user) return;

		setRecipientUser(user);

		const alreadyOn = !!recipientUser;
		// If talkback is just starting, check if audio is off and turn it on
		if (!alreadyOn) {
			if (!getIsUserAudioActive(CONFIG_ID)) {
				toggleAudio(CONFIG_ID);
				audioWasOffBeforeTalkback.current = true;
			} else {
				audioWasOffBeforeTalkback.current = false;
			}
		}
	}, [getIsUserAudioActive, recipientUser, toggleAudio]);

	const stopTalkback = useCallback(() => {
		if (!recipientUser) return;

		if (audioWasOffBeforeTalkback.current) {
			toggleAudio(CONFIG_ID);
			setStopTalkbackWhenAudioOff(true);
			return;
		}

		setRecipientUser(null);
	}, [recipientUser, toggleAudio]);

	// Debounce stop talkback to prevent start/stop spamming
	const requestStopTalkback = useCallback(() => {
		if (stopTalkbackTimer.current) {
			clearTimeout(stopTalkbackTimer.current);
		}

		stopTalkbackTimer.current = setTimeout(() => {
			stopTalkback();
		}, STOP_TALKBACK_DEBOUNCE_TIME);
	}, [stopTalkback]);

	useEffect(() => {
		if (stopTalkbackWhenAudioOff && !getIsUserAudioActive(CONFIG_ID)) {
			setStopTalkbackWhenAudioOff(false);
			setRecipientUser(null);
		}
	}, [getIsUserAudioActive, stopTalkbackWhenAudioOff]);

	const userAudioRequestError = userAudioRequestErrors.find((e) => (
		e.configId === CONFIG_ID && e.error
	));

	const value = useMemo(() => ({
		isDisconnecting: stopTalkbackWhenAudioOff,
		recipientUser,
		startTalkback,
		stopTalkback: requestStopTalkback,
		talkbackActiveTrack,
		userAudioRequestError,
	}), [
		recipientUser,
		requestStopTalkback,
		startTalkback,
		stopTalkbackWhenAudioOff,
		talkbackActiveTrack,
		userAudioRequestError,
	]);

	return (
		<MediaTalkbackContext.Provider value={value}>
			{children}
		</MediaTalkbackContext.Provider>
	);
};
