// @ts-check

/**
 * Store subscribed MediaStreamTrack and its id, available for consumption.
 */
export default class Track {
	/**
	 * @param {MediaStreamTrack | { id: string }} mediaStreamTrack
	 * @param {string} [id]
	 */
	constructor(mediaStreamTrack, id) {
		this.id = id || mediaStreamTrack.id;
		this.mediaStreamTrack = mediaStreamTrack;
	}

	clone() {
		if (this.mediaStreamTrack instanceof MediaStreamTrack) {
			return new Track(
				this.mediaStreamTrack.clone(),
				this.id,
			);
		}

		return new Track(this.mediaStreamTrack);
	}
}
