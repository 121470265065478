/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { DEFAULT_MODE } from '../ChannelButtonsPanel/PipLayoutProvider';
import { isMobile } from '../../lib/userAgent';
import { getLayerIndex } from '../../store/lib/helpers';
import { PipLayoutAuction, PipLayoutAudio, PipLayoutConnected } from './PipLayout';
import './PipSelector.scss';

const MOBILE_HIDE_OVERLAY_TIMEOUT = 4 * 1000;
const HOVER_DELAY = 100;

const generateLayers = (slotNumber) => {
	const layers = ['main'];
	for (let layer = 1; layer < slotNumber; layer += 1) {
		layers.push(`pip${layer}`);
	}
	return layers;
};

const MultiParticipantsDisplayConfig = {
	DEFAULT_MODE: {
		layers: generateLayers(3),
	},
	HALF_RATIO: {
		layers: generateLayers(2),
	},
	HALF_THIRD_THIRD: {
		layers: generateLayers(3),
	},
	CENTER_ALL_BOTTOM: {
		layers: generateLayers(6),
	},
	CENTER_ALL_RIGHT: {
		layers: generateLayers(5),
	},
	MOSAIC_QUAD: {
		layers: generateLayers(4),
	},
	MOSAIC_SIX: {
		layers: generateLayers(6),
	},
	MOSAIC_TWELVE: {
		layers: generateLayers(12),
	},
	MOSAIC_SIXTEEN: {
		layers: generateLayers(16),
	},
	FULL_CANVAS: {
		layers: generateLayers(1),
	},
	AUCTION: {
		layers: generateLayers(5),
	},
};

export const SourceCardPipSelector = ({
	alwaysShowButtons,
	displayMode,
	isAudio,
	liveLayerOfSource,
	onHover,
	setSourceOnLayer,
}) => {
	const [showLayoutButtons, setShowLayoutButtons] = useState(false || alwaysShowButtons);
	const layoutImageWrapperRef = useRef();
	const timeoutRef = useRef();

	const currentMode = useMemo(() => (MultiParticipantsDisplayConfig[displayMode]), [displayMode]);

	const handleSelectPip = useCallback((event, layer) => {
		event.stopPropagation();
		setSourceOnLayer(layer);
		if (isMobile) {
			setShowLayoutButtons(false || alwaysShowButtons);
			onHover(false);
		}
	}, [alwaysShowButtons, onHover, setSourceOnLayer]);

	useEffect(() => {
		if (layoutImageWrapperRef.current) {
			const onClick = (e) => {
				const { target: { index } } = e;
				handleSelectPip(e, currentMode.layers[index]);
			};
			const wrapperFirstChild = layoutImageWrapperRef.current;
			if (!wrapperFirstChild) return undefined;
			const svgElement = wrapperFirstChild.querySelector('.PipLayout');

			const childs = svgElement.childNodes;
			childs.forEach((child, index) => {
				const layerIndex = getLayerIndex(liveLayerOfSource);

				if (layerIndex === index) child.classList.add('pipActive');
				else child.classList.remove('pipActive');

				if (displayMode === DEFAULT_MODE && index > 0) {
					child.style.opacity = 0.75;
				}

				child.index = index;
				child.classList.add('SourceCardPipSelectorInMode');
				child.addEventListener('click', onClick);
			});

			return () => {
				childs.forEach((child) => {
					child.removeEventListener('click', onClick);
				});
			};
		}
		return undefined;
	}, [handleSelectPip, liveLayerOfSource, currentMode, displayMode, showLayoutButtons]);

	useEffect(() => () => {
		clearTimeout(timeoutRef.current);
	}, []);

	const hoverTimeoutRef = useRef();

	const handleMouseEnter = () => {
		onHover(true);
		setShowLayoutButtons(true);
	};

	const handleMouseEnterDelayed = () => {
		clearTimeout(hoverTimeoutRef.current);
		hoverTimeoutRef.current = setTimeout(() => {
			handleMouseEnter(false);
		}, HOVER_DELAY);
	};

	useEffect(() => () => {
		clearTimeout(hoverTimeoutRef.current);
	}, []);

	const handleMouseLeave = () => {
		clearTimeout(hoverTimeoutRef.current);
		onHover(false);
		setShowLayoutButtons(false || alwaysShowButtons);
	};

	const handleClickLayoutWrapper = (e) => {
		e.stopPropagation();
		clearTimeout(timeoutRef.current);
		clearTimeout(hoverTimeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setShowLayoutButtons(false || alwaysShowButtons);
			onHover(false);
		}, MOBILE_HIDE_OVERLAY_TIMEOUT);
		setShowLayoutButtons(true);
		onHover(true);
	};

	return (
		<>
			<div className="position-absolute h-100 w-100" />
			{displayMode && (
				<div
					className="SourceCardPipSelectorWrapper position-absolute overflow-hidden h-100 w-100"
					onClick={handleClickLayoutWrapper}
					onMouseLeave={!isMobile ? handleMouseLeave : undefined}
					onMouseOverCapture={!isMobile ? handleMouseEnterDelayed : undefined}
				>
					{showLayoutButtons && (
						<div
							className={clsx(
								'd-flex p-1 w-100 h-100 align-items-center justify-content-center',
							)}
							ref={layoutImageWrapperRef}
						>
							<div className="h-100" style={{ aspectRatio: '16/9' }}>
								{
									isAudio
										? (<PipLayoutAudio />)
										: displayMode === 'AUCTION'
											? (<PipLayoutAuction />)
											: (<PipLayoutConnected layout={displayMode} />)
								}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

SourceCardPipSelector.propTypes = {
	alwaysShowButtons: PropTypes.bool,
	displayMode: PropTypes.string,
	isAudio: PropTypes.bool,
	liveLayerOfSource: PropTypes.string,
	onHover: PropTypes.func.isRequired,
	setSourceOnLayer: PropTypes.func.isRequired,
};

SourceCardPipSelector.defaultProps = {
	alwaysShowButtons: false,
	displayMode: undefined,
	isAudio: false,
	liveLayerOfSource: undefined,
};
