/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Badge, Card, Col } from 'reactstrap';
import { FastAverageColor } from 'fast-average-color';

import { AVATAR_SIZE, getFileUrl } from '../../../lib/file';
import { getWatchLink } from '../../../RoutePath';
import { useLocalizedTimeAgo } from '../../../lib/timeAgo';
import { useValidateImage } from '../../../lib/useValidateImage';
import { VideoThumbnail } from '../../Video/Thumbnail';
import defaultAvatar from '../../../images/default-avatar.png';
import Hexagon from '../../Hexagon/Hexagon';

import './Item.scss';

/**
 * @typedef {{
 * 	channelAvatar?: string?,
 * 	minimal?: boolean,
 * }} ChannelAvatarProps
 */

const ChannelAvatar = (
	/** @type {ChannelAvatarProps} */
	{ channelAvatar, minimal = false },
) => {
	const userAvatar = channelAvatar
		? getFileUrl({ name: channelAvatar }, AVATAR_SIZE.width)
		: defaultAvatar;
	const validateImage = useValidateImage(defaultAvatar);

	return (
		<Hexagon
			className={clsx(
				'avatar-icon rounded-0 shadow-none',
				{ 'd-30': minimal },
			)}
		>
			<img className="img-fit-container" alt="avatar" src={userAvatar} onError={validateImage} />
		</Hexagon>
	);
};

/**
 * @typedef {{
 * 	className?: string,
 *  embedLink?: boolean,
* 	minimalInMobile?: boolean,
 *  size?: {
 * 		xs?: string,
 * 		sm?: string,
 * 		md?: string,
 * 		lg?: string,
 * 		xl?: string,
 * 	},
* 	video: import('../../../api/elasticsearch').ESVodResult,
 * }} VideoItemProps
 */

export const VideoItem = (
	/** @type {VideoItemProps} */
	{
		className = '',
		embedLink = false,
		minimalInMobile = false,
		size: {
			xs = '12',
			sm = '6',
			md = '6',
			lg = '4',
			xl = '3',
		} = {},
		video,
	},
) => {
	const [minimalThumbnailTextColor, setMinimalThumbnailTextColor] = useState(
		/** @type {string | undefined} */(undefined),
	);
	const timeAgo = useLocalizedTimeAgo();
	const createdAtFormatted = video.createdAt && timeAgo.format(new Date(video.createdAt), 'round');

	//if (!video.channelHashtag || !video.watchLinkId) throw new Error('Missing channelHashtag or watchLinkId');

	const handleThumbnailLoaded = useCallback((
		/** @type {HTMLImageElement | HTMLVideoElement}*/element,
	) => {
		const fac = new FastAverageColor();
		const bgColor = fac.getColor(element);

		setMinimalThumbnailTextColor(bgColor.isDark ? 'text-white' : 'text-black');
	}, []);

	return (
		<Col
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
			xs={xs}
			className={clsx('VideoItem', className)}
		>
			<Link to={getWatchLink(video.channelHashtag, video.watchLinkId, embedLink)}>
				<Card className="card-transparent card-box-hover-rise rounded overflow-hidden fix-overflow">
					<VideoThumbnail
						absoluteChildren={minimalInMobile && (
							<div className="position-absolute h-100 w-100 d-flex d-sm-none flex-column justify-content-end">
								<div className="d-flex flex-row VideoItem_blurred">
									<div className="avatar-icon-wrapper d-flex align-items-center mx-1">
										<ChannelAvatar channelAvatar={video.channelAvatar} minimal />
									</div>
									{minimalThumbnailTextColor && (
										<div
											className={clsx(
												'd-flex flex-column justify-content-center overflow-hidden font-size-sm',
												minimalThumbnailTextColor,
											)}
										>
											<strong className="text-truncate">{video.title}</strong>
											<span className="text-truncate">{video.channelLabel}</span>
										</div>
									)}
								</div>
							</div>
						)}
						className="mx-auto h-100 w-100"
						onThumbnailLoaded={minimalInMobile ? handleThumbnailLoaded : undefined}
						video={video}
					/>
					<div
						className={clsx(
							'bg-darker content-darker w-100 align-items-center justify-content-between p-2',
							minimalInMobile ? 'd-none d-sm-flex' : 'd-flex',
						)}
					>
						<div className="d-flex overflow-hidden">
							<div className="avatar-icon-wrapper">
								<ChannelAvatar channelAvatar={video.channelAvatar} />
							</div>
							<div className="d-flex flex-column justify-content-center overflow-hidden font-size-sm">
								<strong>{video.title}</strong>
								<span>{video.channelLabel}</span>
							</div>
						</div>
						<div className="d-flex align-items-end flex-column">
							{video.status === 'ready' && (
								<Badge className="bg-light content-light content-fill-svg" pill>
									<FaRegEye className="mr-1" />
									<span className="content-light">{video.vodViews || 0}</span>
								</Badge>
							)}
							{createdAtFormatted && <div className="font-size-xs content-light text-end">{createdAtFormatted}</div>}
						</div>
					</div>
				</Card>
			</Link>
		</Col>
	);
};
