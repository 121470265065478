import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PlayerModeContext = createContext({});
export const usePlayerMode = () => useContext(PlayerModeContext);

export const PlayerModes = {
	FLOATING_PIP: 'FLOATING_PIP',
	DRAW: 'DRAW',
	CROP: 'CROP',
};

export const PlayerModeProvider = ({
	children,
	isController,
}) => {
	const [modeState, setMode] = useState();
	const defaultMode = isController ? PlayerModes.FLOATING_PIP : undefined;
	const mode = modeState || defaultMode;

	const contextValue = useMemo(() => ({
		mode,
		modeState,
		setMode,
	}), [
		mode,
		modeState,
		setMode,
	]);

	return (
		<PlayerModeContext.Provider value={contextValue}>
			{children}
		</PlayerModeContext.Provider>
	);
};

PlayerModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
	isController: PropTypes.bool,
};

PlayerModeProvider.defaultProps = {
	isController: false,
};
