import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const NicknameContext = createContext({});
export const useNickname = () => useContext(NicknameContext);

export const NicknameProvider = ({
	children,
}) => {
	const [isDrawNicknameEnabled, setIsDrawNicknameEnabled] = useState(false);

	const toggle = useCallback(() => {
		setIsDrawNicknameEnabled((prevState) => !prevState);
	}, []);

	const contextValue = useMemo(() => ({
		toggle,
		isDrawNicknameEnabled,
		setIsDrawNicknameEnabled,
	}), [
		toggle,
		isDrawNicknameEnabled,
		setIsDrawNicknameEnabled,
	]);

	return (
		<NicknameContext.Provider value={contextValue}>
			{children}
		</NicknameContext.Provider>
	);
};

NicknameProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
