// @ts-check

import { createSelector } from 'reselect';

export const STATE_KEY = 'tracks';

/** @import { RootState } from '..'; */

/**
 * selectors
 * @param {RootState} state
 */
export const selectTracks = (state) => state[STATE_KEY];

export const selectAudioTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'audio'),
);

export const selectVideoTracks = createSelector(
	selectTracks,
	(tracks = []) => tracks.filter((t) => t.mediaStreamTrack instanceof MediaStreamTrack && t.mediaStreamTrack.kind === 'video'),
);
